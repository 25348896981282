import React, {Component} from "react";
import Layout from "../components/layout";
import styles from "./styles/about.module.css";
import Title from "../components/title";
import JobContent from "../components/jobContent";
import {experience,education} from "../data/aboutData";
import EducationContent from "../components/educationContent";

class About extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <Layout>
                <div className={styles.wrapper}>
                    <div className={styles.name}>
                        <h1>Takahide Nakamura</h1>
                        <label>Based in San Francisco, CA, USA</label>
                    </div>
                    <div className={styles.description}>
                        <div style={{backgroundImage:`url(${require("../images/TakahideNakamura.png")})`}} />
                        <p>I love to challenge new skills and new representation not only a compositing part but also other department parts such as modeling, animation, and dynamics effects. Therefore, I do not make a limit for me to learn. I understand how important the reference footage is for creating a future world, so I usually observe around me, keep looking for good videos on the Vimeo and watch a lot of movies.</p>
                    </div>
                    <div className={styles.skills}>
                        <div className={styles.software}>
                            <h5>Software Proficiency</h5>
                            <ul>
                                <li><img src={require("../images/software/Maya.png")}/></li>
                                <li><img src={require("../images/software/Ae.png")}/></li>
                                <li><img src={require("../images/software/Nuke.png")}/></li>
                                <li><img src={require("../images/software/Houdini.png")}/></li>
                                <li><img src={require("../images/software/PFTrack.png")}/></li>
                                <li><img src={require("../images/software/Ps.png")}/></li>
                                <li><img src={require("../images/software/Ai.png")}/></li>
                                <li><img src={require("../images/software/Pr.png")}/></li>
                                <li><img src={require("../images/software/Vray.png")}/></li>
                                <li><img src={require("../images/software/Mari.png")}/></li>
                                <li><img src={require("../images/software/Mudbox.png")}/></li>
                                <li><img src={require("../images/software/Softimage.png")}/></li>
                                <li><img src={require("../images/software/Vred.png")}/></li>
                                <li><img src={require("../images/software/Zbrush.png")}/></li>
                            </ul>
                        </div>
                        <div className={styles.language}>
                            <h5>Language</h5>
                            <ul>
                                <li>Japanese</li>
                                <li>English</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.education}>
                        <h5>Education</h5>
                        <ul>
                            <li>
                                <div>
                                    <h6>Academy of Art University</h6>
                                    <label>2017 - 2019</label>
                                </div>
                                <p>MA in Animation & Visual Effects Specialized in VFX compositing and Dynamics</p>
                            </li>
                            <li>
                                <div>
                                    <h6>HAL Osaka College of Technology & Design</h6>
                                    <label>2010 - 2014</label>
                                </div>
                                <p>Advanced Diploma in 3DCG Animation Department</p>
                            </li>
                        </ul>
                    </div>
                    <a href={require("../data/Resume_TakahideNakamura.pdf")} className={styles.downloadResumeButton}>Download Resume</a>
                </div>

                

            </Layout>
        );
    }
}

export default About;