import React, {Component} from "react";
import styles from "./styles/jobContent.module.css";

class JobContent extends Component{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <li className={styles.content}>

                <header>
                    <div className={styles.headerTop}>
                        <h3>{this.props.item.title}</h3>
                        <h4><span>{this.props.item.projectName}</span> - {this.props.item.projectType}</h4>
                    </div>
                    <label>{this.props.item.location}</label>
                </header>
            
                <ul>
                    {this.props.item.job.map((item,index)=>{
                        return <li key={`job_${index}`}>{item}</li>
                    })}
                </ul>
            
            </li>
        )
    }
}

export default JobContent;
