import React, {Component} from "react"
import styles from "./style.module.css"

class EducationContent extends Component{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <li className={styles.root}>
                <header>
                    <h3>{this.props.item.school}</h3>
                    <div>
                        <label>{this.props.item.yearStart}</label>
                        <label>{this.props.item.yearEnd}</label>
                    </div>
                </header>
                <h4>{this.props.item.major}</h4>
            </li>
        );
    }
}

export default EducationContent;